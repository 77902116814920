// Modal.js
import React, { useEffect } from 'react';

const Modal = ({ show, handleClose, children }) => {
  // Close modal when the Escape key is pressed
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    if (show) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [show, handleClose]);

  // Close modal if you click outside the modal content
  const handleClickOutside = (e) => {
    if (e.target.classList.contains('modal-background')) {
      handleClose();
    }
  };

  // Don't render anything if 'show' is false
  if (!show) return null;

  return (
    <div className="modal modal-background" onClick={handleClickOutside}>
      <div className="modal-main" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={handleClose}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
