import React from 'react';
import { servicesContent } from '../data/copy.js';

const Services = () => {
  return (
    <section className="services">
      <h3>{servicesContent.title}</h3>
      <ul>
        <li>{servicesContent.service1Title}</li>
        <p>{servicesContent.service1SubTitle}</p>
        <p>{servicesContent.service1Description}</p>
        <li>{servicesContent.service2Title}</li>
        <p>{servicesContent.service2SubTitle}</p>
        <p>{servicesContent.service2Description}</p>
        <li>{servicesContent.service3Title}</li>
        <p>{servicesContent.service3SubTitle}</p>
        <p>{servicesContent.service4Description}</p>
        <li>{servicesContent.service4Title}</li>
        <p>{servicesContent.service4SubTitle}</p>
        <p>{servicesContent.service4Description}</p>
      </ul>
    </section>
  );
};

export default Services;
