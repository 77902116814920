import React from 'react';
import { aboutContent } from '../data/copy.js';

const About = () => {
  return (
    <section className="about">
      <h3>{aboutContent.title}</h3>
      <p>{aboutContent.paragraph1}</p>
      <p>{aboutContent.paragraph2}</p>
      <p>{aboutContent.paragraph3}</p>
      <p>{aboutContent.paragraph4}</p>
      <p>{aboutContent.paragraph5}</p>
      <p>{aboutContent.paragraph6}</p>
    </section>
  );
};

export default About;
