import React from 'react';
import { ctaContent } from '../data/copy.js';
import { contactFormContent } from '../data/copy.js';

const CTA = ({ openModal, submitStatus }) => {
  return (
    <section className="cta">
      <h3>{ctaContent.tagline}</h3>
      <p>{ctaContent.paragraph}</p>

      {submitStatus === 'success' ? (
        // Display success message when form is submitted
        <div className="success-message">
          <h3>{contactFormContent.successTitle}</h3>
        </div>
      ) : (
        // Display CTA button if form is not yet submitted
        <button className="cta-button" onClick={openModal}>
          {ctaContent.butoonText}
        </button>
      )}
    </section>
  );
};

export default CTA;
