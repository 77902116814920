import React, { useState } from 'react';
import Header from './components/Header';
import CTA from './components/CTA';
import About from './components/About';
import Services from './components/Services';
import ContactForm from './components/ContactForm';
import Modal from './components/Modal';
import './App.css';

const App = () => {
  const [showModal, setShowModal] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);  // Track form submission status

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFormSubmission = () => {
    setHasSubmitted(true);
    setSubmitStatus('success');  // Set form submission status to success
    handleCloseModal();
  };

  return (
    <div className="app-container">
      <Header />
      <CTA openModal={handleOpenModal} submitStatus={submitStatus} /> {/* Pass submitStatus */}
      <Services />
      <About />

      {!hasSubmitted && (
        <ContactForm closeModal={handleCloseModal} onFormSubmit={handleFormSubmission} />
      )}

      <Modal show={showModal} handleClose={handleCloseModal}>
        <ContactForm closeModal={handleCloseModal} onFormSubmit={handleFormSubmission} />
      </Modal>
    </div>
  );
};

export default App;
