export const headerContent = {
  title: 'Expert Oversight for Web, App, and Software Development Projects',
  subtitle: 'Providing strategic leadership and hands-on management to ensure success in your development projects.',
};

export const ctaContent = {
  tagline: 'Your Strategic Partner for Vendor Management and Project Oversight',
  paragraph: 'With over two decades of experience across industries including government, healthcare, gaming, and defense, I provide expert oversight to web, app, and software development projects. My focus is on ensuring clarity in vendor agreements, managing deadlines, maintaining accessibility standards, and delivering solutions aligned with industry best practices. Whether overseeing large-scale government websites or developing immersive digital experiences, my mission is to guide projects toward successful, innovative outcomes.',
  buttonText: 'Get Your Free Consultation',
};

export const servicesContent = {
  title: 'Services',
  
  service1Title: 'Contract Review and Negotiation',
  service1SubTitle: 'Ensuring Fair, Transparent, and Cost-Effective Agreements',
  service1Description: 'I offer expert contract review and negotiation services, ensuring vendor agreements are clear, aligned with project goals, and cost-effective. Whether it\'s a government contract or a healthcare platform, I ensure terms are fair, deliverables are well-defined, and accessibility is integrated from the start.',
  
  service2Title: 'Project Oversight',
  service2SubTitle: 'Guiding Projects to Successful Completion',
  service2Description: 'I oversee the entire project lifecycle, ensuring that development efforts meet timelines, stay within budget, and maintain the highest quality and accessibility standards. From healthcare tools to gaming platforms, I ensure seamless coordination and accountability at every stage.',
  
  service3Title: 'Vendor Accountability',
  service3SubTitle: 'Holding Vendors to Their Commitments',
  service3Description: 'I advocate for businesses by holding vendors accountable for delivering on their promises. With experience across sectors like government, gaming, and healthcare, I ensure transparency, open communication, and timely delivery of high-quality, accessible solutions.',
  
  service4Title: 'UX Research and Design',
  service4SubTitle: 'Creating Intuitive, User-Centered Digital Experiences',
  service4Description: 'I specialize in UX research and design, delivering intuitive and accessible user experiences. From prototyping healthcare tools to developing immersive gaming experiences, my user-centered approach ensures interfaces are functional, engaging, and meet the highest accessibility standards.',
};

export const aboutContent = {
  title: 'My Story',
  
  paragraph1: 'I began my career in interactive media design and have since expanded my expertise across various industries. My journey has taken me through video game design, government defense projects, healthcare platforms, and beyond—all while maintaining a focus on user-centered, innovative solutions.',
  
  paragraph2: 'In the gaming industry, I started as a Level Designer for Gearbox Software’s *Brothers in Arms: Earned in Blood*. At Edge of Reality, I contributed to AAA titles like *The Incredible Hulk* and *Over the Hedge*, where I honed my skills in real-time 3D graphics and gameplay design. *Over the Hedge* was nominated for the *2007 IAA Children’s Game of the Year*.',
  
  paragraph3: 'In 2008, I transitioned to the Government and Defense sector, joining Intific, Inc., where I led the design and prototyping of mission-critical applications for DARPA and other government agencies. My work on SpaceForce, an HTML5-based learning game for K-3 students, demonstrated my ability to create secure, high-impact educational solutions for government projects.',
  
  paragraph4: 'In Healthcare and Scientific Research, I’ve developed clinical assessment tools for Pearson Clinical, designed mobile apps for behavioral health, and contributed to cognitive load research at the University of Texas. My work focused on integrating UX design with cutting-edge psychological tools to improve user outcomes.',
  
  paragraph5: 'In Commercial Applications, I have delivered solutions across various industries, from UX research for large-scale government systems to creating AR platforms for interactive experiences. My recent work at Fearless involved modernizing digital platforms with a focus on accessibility and seamless user experience. Earlier, I developed AR content pipelines for Meta3D Studios and worked with Streaming Curiosity to enhance digital experiences through AR and UI/UX design.',
  
  paragraph6: 'Beyond my professional projects, I have a deep passion for education. I have taught courses in game design and interactive media at Austin Community College and DeVry University, sharing my expertise in AR/VR technologies and interactive design to help train the next generation of innovators.',
};

export const contactFormContent = {
  successTitle: 'Thank you for reaching out! I will get back to you shortly.',
};
