import React, { useState } from 'react';
import { contactFormContent } from '../data/copy.js';
import emailjs from 'emailjs-com';

const ContactForm = ({ closeModal, onFormSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    projectDescription: '',
    service: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  // Simple validation logic
  const validate = () => {
    const errors = {};
    
    if (!formData.name) errors.name = 'Name is required';
    
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }

    if (formData.phone && !/^\d{10}$/.test(formData.phone)) {
      errors.phone = 'Phone number must be 10 digits';
    }

    if (!formData.service) {
      errors.service = 'Please select a service';
    }

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Validate form data
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    // Clear errors and start submitting
    setErrors({});
    setIsSubmitting(true);
  
    // Send email via EmailJS
    emailjs
      .send(
        'service_52qyzhj',
        'template_2d4ygbo',
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          service: formData.service,
          projectDescription: formData.projectDescription,
        },
        'm03-JQWD9bkTzyAwx'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setIsSubmitting(false);
          setSubmitStatus('success');
  
          // Notify App.js that form has been submitted
          if (onFormSubmit) onFormSubmit();
  
          // Automatically close the modal after 3 seconds
          setTimeout(() => {
            if (closeModal) closeModal();
          }, 3000);
        },
        (err) => {
          console.error('FAILED...', err);
          setIsSubmitting(false);
          setSubmitStatus('error');
          // Optionally, display an error message to the user
        }
      );
  };
 

  return (
    <section className="contact-form">
      {submitStatus === 'success' ? (
        // Display success message
        <div className="success-message">
          <h3>{contactFormContent.successTitle}</h3>
          <p>{contactFormContent.successMessage}</p>
        </div>
      ) : (
        // Display form if not submitted
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input 
              type="text" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </label>
          
          <label>
            Email:
            <input 
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </label>
          
          <label>
            Phone Number (optional):
            <input 
              type="tel" 
              name="phone" 
              value={formData.phone} 
              onChange={handleChange} 
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </label>
          
          <label>
            Project Description (optional):
            <textarea 
              name="projectDescription" 
              value={formData.projectDescription} 
              onChange={handleChange} 
            />
          </label>
          
          <label>
            How can I help you?
            <select 
              name="service" 
              value={formData.service} 
              onChange={handleChange}
            >
              <option value="">Select Service</option>
              <option value="Contract Review">Contract Review</option>
              <option value="Project Oversight">Project Oversight</option>
              <option value="Vendor Accountability">Vendor Accountability</option>
              <option value="UX Research & Design">UX Research & Design</option>
              <option value="Other Inquiry">Other Inquiry</option>
            </select>
            {errors.service && <span className="error">{errors.service}</span>}
          </label>

          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      )}
    </section>
  );
};

export default ContactForm;
