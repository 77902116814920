import React from 'react';
import { headerContent } from '../data/copy.js';

const Header = () => {
  console.log(headerContent);
  return (
    <header className="header">
      <h1>{headerContent.title}</h1>
      <h2>{headerContent.subtitle}</h2>
    </header>
  );
};

export default Header;
